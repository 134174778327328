<template>
  <div class="selectList">
    <!-- <el-checkbox-group v-model="adsetList" @change="selectAdset">
            <el-checkbox :label="item.id" v-for="item in list" :key="item.id">{{item.name}}</el-checkbox>
        </el-checkbox-group> -->
    <el-input
      placeholder="输入关键字进行过滤2"
      v-model="filterText"
      size="mini"
      style="margin-bottom: 10px"
    ></el-input>
    <el-dropdown
      @command="selectAll"
      style="margin-bottom: 10px"
    >
      <span class="el-dropdown-link">
        {{ selectType == 3 ? '全选广告系列' : selectType == 1 ? '全选广告组' : selectType == 2 ? '全选广告' : '默认'
        }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :command="0">默认</el-dropdown-item>
        <el-dropdown-item :command="3">全选广告系列</el-dropdown-item>
        <el-dropdown-item :command="1">全选广告组</el-dropdown-item>
        <el-dropdown-item :command="2">全选广告</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <el-select size="mini"  v-model="selectType" @change="">
            <el-option label="默认" :value="0"></el-option>
            <el-option label="全选广告系列" :value="3"></el-option>
            <el-option label="全选广告组" :value="1"></el-option>
            <el-option label="全选广告" :value="2"></el-option>
        </el-select> -->
    <el-tree
      :data="list"
      node-key="id"
      :check-strictly="true"
      :default-expand-all="true"
      :props="defaultProps"
      @check="checkedNode"
      ref="tree"
      :expand-on-click-node="false"
      :filter-node-method="filterNode"
      :show-checkbox="true"
      :default-checked-keys="defaultSelectCheckedKeys"
      :highlight-current="true"
      @node-click="addNode"
    >
      <span
        :class="['myTreeNode']"
        slot-scope="{ node, data }"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="node.label"
          placement="right"
          :enterable="false"
        >
          <span
            >{{ node.label }}
            <span
              class="videoTips"
              v-if="data.creative && mediaTypeMap[data.id].media_type == 'video' && mediaTypeMap[data.id].videoId"
              >{{
                mediaTypeMap[data.id].videoStatus == 'processing'
                  ? '视频上传中'
                  : mediaTypeMap[data.id].thumbUrl
                  ? ''
                  : '请选择封面'
              }}</span
            >
            <span
              class="videoTips"
              v-if="tipsMap.budgetMap[data.id]"
              >{{ tipsMap.budgetMap[data.id] }}</span
            >
            <span
              class="videoTips"
              v-if="tipsMap.bidMap[data.id]"
              >{{ tipsMap.bidMap[data.id] }}</span
            >
          </span>
        </el-tooltip>
        <el-button
          icon="el-icon-delete"
          class="delete"
          type="primary"
          size="mini"
          @click.stop="deleteAds(node, data)"
        ></el-button>
      </span>
    </el-tree>
  </div>
</template>
<script>
import { myThirdLoginSetHasLabelByPage } from '@/api/common/user';
import { draftDel } from '@/api/creatAd.js';
export default {
  props: ['list', 'defaultSelect', 'currentLevel', 'type', 'mediaTypeMap', 'invaild_insterest', 'params'],
  data() {
    return {
      chooseList: [],
      checkList: this.list.map((v) => v.id),
      max: 10,
      ctrlStatus: false,
      selectlist: [],
      level: this.currentLevel,
      defaultProps: {
        label: 'name',
        children: 'list',
        disabled: (data, node) => {
          return (
            (this.invaild_insterest && this.invaild_insterest.length > 0) ||
            (this.type == 'draft' && !data.can_edit) ||
            (this.params?.target_obj_level == 'CAMPAIGN' &&
              data.level == 'campaign' &&
              !this.params?.is_create_target) ||
            (this.params?.target_obj_level == 'ADSET' && data.level == 'adset') ||
            (this.params?.target_obj_level == 'ADSET' && data.level == 'campaign')
          );
        },
      },
      defaultSelectCheckedKeys: JSON.parse(JSON.stringify(this.defaultSelect)),
      selectType: 0,
      filterText: '',
    };
  },
  computed: {
    adsetList: {
      get: function () {
        return this.checkList;
      },
      set: function (v) {
        if (v.length == 0) {
          return;
        }
        if (v.length > this.checkList.length && !this.ctrlStatus) {
          this.checkList = [v[v.length - 1]];
        } else {
          this.checkList = v;
        }
      },
    },
    tipsMap: {
      get() {
        let bidMap = {},
          budgetMap = {};
        this.list.forEach((campaign) => {
          campaign.list.forEach((adset) => {
            if (
              (adset.bid_strategy == 'COST_CAP' || adset.bid_strategy == 'LOWEST_COST_WITH_BID_CAP') &&
              !Number(adset.bid_amount)
            ) {
              bidMap[adset.id] = '请填写出价';
            }
            if (adset.bid_strategy == 'LOWEST_COST_WITH_MIN_ROAS' && !Number(adset.roas_average_floor)) {
              bidMap[adset.id] = '请填写最低roas';
            }
            if (!campaign.is_cbo && !Number(adset.lifetime_budget) && !Number(adset.daily_budget)) {
              budgetMap[adset.id] = '请设置组预算';
            }
          });
          if (campaign.is_cbo && !Number(campaign.lifetime_budget) && !Number(campaign.daily_budget)) {
            budgetMap[campaign.id] = '请设置系列预算';
          }
        });
        return { bidMap, budgetMap };
      },
    },
  },
  methods: {
    getAdsList() {
      let list = [];
      this.defaultSelectCheckedKeys.map((v) => {
        this.list.map((k) => {
          k.list.map((m) => {
            m.list.map((z) => {
              if (v == z.id) {
                list.push(z);
              }
            });
          });
        });
      });
      return list;
    },
    checkAdTypes(a) {
      let mediaTypes = this.getAdsList().map((v) => v.creative.media_type);
      let objectives = this.getAdsList().map((v) => v.objective);
      // 已选择动态广告并且已经选择主页赞
      if (mediaTypes.includes('asset_feed') && objectives.includes('PAGE_LIKES')) {
        // 当前是动态广告并且是主页赞
        if (a.creative.media_type == 'asset_feed' && a.objective == 'PAGE_LIKES') {
          return { flag: true, msg: '' };
        } else {
          return { flag: false, msg: '广告类型不能混合编辑(已选择的广告不是动态广告，或者不是主页赞类型)' };
        }
        // 已选择的广告是动态广告并且已经选择帖子广告
      } else if (mediaTypes.includes('asset_feed') && objectives.includes('POST_ENGAGEMENT')) {
        if (a.creative.media_type == 'asset_feed' && a.objective == 'POST_ENGAGEMENT') {
          return { flag: true, msg: '' };
        } else {
          return { flag: false, msg: '广告类型不能混合编辑(已选择的广告不是动态广告，或者不是贴文互动类型)' };
        }
        // 已选择的广告是动态广告并且没有选择主页赞或者帖子广告
      } else if (
        mediaTypes.includes('asset_feed') &&
        !objectives.includes('POST_ENGAGEMENT') &&
        !objectives.includes('PAGE_LIKES')
      ) {
        if (
          a.creative.media_type == 'asset_feed' &&
          a.objective !== 'POST_ENGAGEMENT' &&
          a.objective !== 'PAGE_LIKES'
        ) {
          return { flag: true, msg: '' };
        } else {
          return { flag: false, msg: '广告类型不能混合编辑(已选择的广告不是动态广告，或者是主页广告)' };
        }
        // 已选择的广告不包含动态广告但是包含主页赞
      } else if (!mediaTypes.includes('asset_feed') && objectives.includes('PAGE_LIKES')) {
        if (a.creative.media_type !== 'asset_feed' && a.objective == 'PAGE_LIKES') {
          return { flag: true, msg: '' };
        } else {
          return { flag: false, msg: '广告类型不能混合编辑(选择的广告是动态广告，或者不是主页赞类型)' };
        }
        // 已选择的广告不包含动态广告但是包含贴文互动
      } else if (!mediaTypes.includes('asset_feed') && objectives.includes('POST_ENGAGEMENT')) {
        if (a.creative.media_type !== 'asset_feed' && a.objective == 'POST_ENGAGEMENT') {
          return { flag: true, msg: '' };
        } else {
          return { flag: false, msg: '广告类型不能混合编辑(选择的广告是动态广告，或者不是贴文互动类型)' };
        }
        // 已选择的广告不是动态广告并且没有选择主页赞或者帖子广告
      } else if (
        !mediaTypes.includes('asset_feed') &&
        !objectives.includes('POST_ENGAGEMENT') &&
        !objectives.includes('PAGE_LIKES')
      ) {
        if (
          a.creative.media_type !== 'asset_feed' &&
          a.objective !== 'POST_ENGAGEMENT' &&
          a.objective !== 'PAGE_LIKES'
        ) {
          return { flag: true, msg: '' };
        } else {
          return { flag: false, msg: '广告类型不能混合编辑(已选择的广告不是动态广告，或者是主页广告)' };
        }
        // 已选择的广告不包含动态广告但是包含主页赞
      }
    },
    // 选中复选框
    checkedNode(a, checked, childSelect) {
      if (this.defaultSelectCheckedKeys.indexOf(a.id) >= 0) {
        if (this.defaultSelectCheckedKeys.length > 1) {
          this.defaultSelectCheckedKeys.splice(this.defaultSelectCheckedKeys.indexOf(a.id), 1);
        } else {
          this.$message({
            type: 'warning',
            message: '请至少保留一个选择项',
          });
          this.defaultSelectCheckedKeys = [a.id];
        }
      } else {
        if (this.level == a.level) {
          if (a.level == 'ad') {
            if (this.checkAdTypes(a).flag) {
              this.defaultSelectCheckedKeys.push(a.id);
            } else {
              this.$message({
                type: 'warning',
                message: this.checkAdTypes(a).msg,
              });
            }
          } else {
            this.defaultSelectCheckedKeys.push(a.id);
          }
          // this.defaultSelectCheckedKeys.push(a.id)
        } else {
          this.defaultSelectCheckedKeys = [a.id];
        }
      }
      // console.log(this.defaultSelectCheckedKeys);
      this.$refs.tree.setCheckedKeys(this.defaultSelectCheckedKeys, true);
      this.level = a.level;
      this.$emit('setSelectList', this.$refs.tree.getCheckedKeys(), this.level);
    },

    // 点击节点
    addNode(a, b, c) {
      if (this.type == 'draft' && !a.can_edit) {
        this.$message({
          type: 'warning',
          message: '不能编辑已经上传的草稿',
        });
        return false;
      }
      if (
        (this.params?.target_obj_level == 'CAMPAIGN' && a.level == 'campaign' && !this.params.is_create_target) ||
        (this.params?.target_obj_level == 'ADSET' && a.level == 'adset') ||
        (this.params?.target_obj_level == 'ADSET' && a.level == 'campaign')
      ) {
        this.$message({
          type: 'warning',
          message: '目标层级的信息不允许修改',
        });
        return false;
      }
      let id = a.id;
      this.defaultSelectCheckedKeys = [a.id];
      this.$refs.tree.setCheckedKeys(this.defaultSelectCheckedKeys, true);
      this.level = a.level;
      this.$emit('setSelectList', this.$refs.tree.getCheckedKeys(), this.level);
      // if(this.defaultSelectCheckedKeys.indexOf(a.id)>0){
      //     if(this.ctrlStatus){
      //         this.defaultSelectCheckedKeys.splice(this.defaultSelectCheckedKeys.indexOf(a.id),1)
      //     }else{
      //         this.defaultSelectCheckedKeys = [a.id]
      //     }
      // }else{
      //     if(this.ctrlStatus && this.level == a.level){
      //         this.defaultSelectCheckedKeys.push(a.id)
      //     }else{
      //         this.defaultSelectCheckedKeys = [a.id]
      //     }
      // }
    },
    // 删除节点
    deleteAds(node, data) {
      this.$confirm('确定要删除该广告组/广告，删除后对应的下级也将随之删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (this.defaultSelectCheckedKeys.length == 1 && this.defaultSelectCheckedKeys[0] == data.id) {
            this.$message({
              type: 'warning',
              message: '请至少保留一个选择项',
            });
            return false;
          } else {
            if (this.type == 'draft') {
              console.log(data);
              let params = {
                draftId: data.draft_id,
                level: data.level,
              };
              this.$showLoading();
              draftDel(params).then((res) => {
                this.$hideLoading();
                if (res.code == 0) {
                  this.$message({
                    type: 'success',
                    message: '删除成功',
                  });
                  this.$emit('deleteAds', data);
                }
              });
            } else {
              this.$emit('deleteAds', data);
            }
          }
        })
        .catch(() => {});

      // console.log(node);
      // console.log(data);
      // console.log(data.level);
      // const parent = node.parent;
      // const children = parent.data.list || parent.data;
      // const index = children.findIndex(d => d.id === data.id);
      // children.splice(index, 1);
      // console.log(children);
      // this.$forceUpdate();
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      let val = this.level == 'ad' ? 2 : this.level == 'adset' ? 1 : 3;
      this.selectAll(val);
      return data.name.indexOf(value) !== -1;
    },
    refuseEdit() {
      this.$message({
        type: 'warning',
        message: '目标层级信息不能修改',
      });
      return;
    },
    selectAll(val) {
      if (
        ((this.params?.target_obj_level == 'CAMPAIGN' && !this.params?.is_create_target && val == 3) ||
          (this.params?.target_obj_level == 'ADSET' && val == 1) ||
          (this.params?.target_obj_level == 'ADSET' && val == 3)) &&
        this.type != 'draft'
      ) {
        this.refuseEdit();
        return;
      }

      this.selectType = val;
      let keys = [];
      let filterList = this.$refs.tree.root.childNodes;
      if (val == 3) {
        // 选择广告组
        filterList.map((v) => {
          if ((v.visible && this.type != 'draft') || (v.data.can_edit && this.type == 'draft')) {
            keys.push(v.key);
          }
        });
        if (!keys.length) {
          this.refuseEdit();
          return;
        }
        this.level = 'campaign';
      }
      if (val == 1) {
        filterList.map((v) => {
          // console.log(v);
          if (v.childNodes.length) {
            v.childNodes.map((k) => {
              console.log(k);
              if ((k.visible && this.type != 'draft') || (k.data.can_edit && this.type == 'draft')) {
                keys.push(k.key);
              }
            });
          }
        });
        if (!keys.length) {
          this.refuseEdit();
          return;
        }
        this.level = 'adset';
      }
      if (val == 2) {
        let key1 = [];
        let key2 = [];
        filterList.map((v) => {
          if (v.childNodes.length) {
            v.childNodes.map((k) => {
              if (k.childNodes.length) {
                k.childNodes.map((m) => {
                  if ((m.visible && this.type != 'draft') || (m.data.can_edit && this.type == 'draft')) {
                    if (m.data.creative.media_type == 'asset_feed') {
                      key1.push(m.key);
                    } else {
                      key2.push(m.key);
                    }
                  }
                });
              }
            });
          }
        });
        keys = key2.length ? key2 : key1;
        if (!keys.length) {
          return;
        }
        this.level = 'ad';
      }
      this.defaultSelectCheckedKeys = keys;
      this.$refs.tree.setCheckedKeys(this.defaultSelectCheckedKeys, true);
      this.$emit('setSelectList', this.$refs.tree.getCheckedKeys(), this.level);
    },
    // 监听树状图的选择
    handleCheckChange(v, k, n) {
      // console.log(v,k);
      // this.checkList = k.checkedKeys;
      // this.defaultSelectCheckedKeys = [...k.checkedKeys]
      // this.$emit('setSelectList',this.checkList)
    },
    selectAdset(v) {
      // this.$emit('setSelectList',this.checkList)
    },
    watchKeyEvent() {
      const setKeyStatus = (keyCode, status) => {
        switch (keyCode) {
          case 16:
          // if (this.onShfit === status) return
          // this.onShfit = status
          // break
          case 17:
            if (this.onCtrl === status) {
              this.ctrlStatus = true;
            } else {
              this.ctrlStatus = false;
            }
            this.onCtrl = status;
            break;
        }
      };
      document.onkeydown = (e) => {
        setKeyStatus(e.keyCode, true);
      };
      document.onkeyup = (e) => {
        setKeyStatus(e.keyCode, false);
      };
    },
  },
  mounted() {
    this.chooseList = JSON.parse(JSON.stringify(this.list));
    this.watchKeyEvent();
  },
  watch: {
    list: {
      handler() {
        this.checkList = this.list.map((v) => v.id);
      },
      deep: true,
    },
    defaultSelect: {
      handler() {
        this.defaultSelectCheckedKeys = JSON.parse(JSON.stringify(this.defaultSelect));
      },
      deep: true,
      immediate: true,
    },
    filterText(val) {
      this.$refs.tree.filter(val);
      this.$nextTick(() => {
        let val = this.level == 'ad' ? 2 : this.level == 'adset' ? 1 : 3;
        this.selectAll(val);
      });
    },
  },
};
</script>
<style lang="scss" scope>
.el-tree-node__content {
  height: auto;
}

.el-tree-node__content {
  // max-width: 160px;
}

.myTreeNode {
  width: 100%;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 1;
  // overflow: hidden;
  // white-space: inherit;
  position: relative;

  .delete {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }

  &:hover {
    .delete {
      display: inline;
    }
  }

  &.treeActive {
    background: #e6effb;
    box-sizing: border-box;
  }

  span {
    display: block;
    width: 100%;
    padding: 5px;
  }
}

.selectList {
  border-right: 1px solid #ddd;
  padding: 0 10px 0 0;

  .el-checkbox {
    display: flex;
    margin: 10px 0;

    .el-checkbox__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-checkbox__input {
      margin-top: 3px;
    }
  }
}
</style>
