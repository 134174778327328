<template>
  <div>
    <el-dialog
      title="确认要提交吗?"
      :visible="creatForm"
      width="600px"
      append-to-body
      @close="f_close"
      @open="openInit"
    >
      <div>
        <el-form
          ref="ruleForm"
          label-width="200px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="是否立即上传到Facebook："
            prop="resource"
          >
            <el-switch
              v-model="is_upload"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="投放开始时间：">
            <el-date-picker
              style="width: 200px"
              v-model="scheduleStartTime"
              type="datetime"
              placeholder="请选择投放开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="f_close">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          id="editCopyEvent"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment';
moment.locale('zh-cn');
import { directCopy } from '@/api/adManagement.js';
export default {
  props: {
    creatForm: {
      type: Boolean,
      default: false,
    },
    level: {
      type: String,
      default: 'campaign',
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      is_upload: true,
      scheduleStartTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 3600 * 1000 * 24 < Date.now();
        },
      },
    };
  },
  watch: {
    scheduleStartTime: {
      handler(currentDate, previousDate) {
        // console.log(currentDate, previousDate, 'currentDate, previousDate');
        if (currentDate && previousDate) {
          const isDateChanged = currentDate.split(' ')[0] !== previousDate.split(' ')[0];
          const isTimeChanged = currentDate.split(' ')[1] !== previousDate.split(' ')[1];
          if (isDateChanged && !isTimeChanged) {
            // console.log('日期发生改变');
            this.scheduleStartTime = `${this.scheduleStartTime.split(' ')[0]} 00:00:00`;
          } 
          // else if (isTimeChanged) {
            // console.log('时分秒发生改变');
          // }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    openInit(){
      this.is_upload = this.level == 'adset'?true:false;
    },
    f_close() {
      this.scheduleStartTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      this.is_upload = this.level == 'adset'?true:false;
      this.$emit('update:creatForm', false);
    },
    sure() {
      // console.log(this.params);
      this.$showLoading();
      directCopy({
        ...this.params,
        is_upload: this.is_upload,
        start_time: this.scheduleStartTime,
      }).then((res) => {
        // console.log(res);
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '创建成功，稍后去草稿箱查看上传结果',
          });
          this.$emit('update:creatForm', false);
        }
      });
    },
  },
};
</script>
